import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function SummaryCards() {
  return (
    <div className='cards'>
      <h1>Check out these exciting challenges and development prospects!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/carbon-footprints-img-1.jpg'
              text='Blockchain technology has infinite applications to promote the use of sustainable processes in the management of finite resources. 
              The development on Blockchain technology enhances transparency and data security, but being still in its infancy stage, it is 
              experiencing teething problems. Many people are unaware how to fully benefit from the technology and creators have not designed 
              sustainable blockchain solutions. The latter is proving to be a barrier for the biggest cryptocurrency in the market. According 
              to Cambridge researchers “mining” of the Bitcoin cryptocurrency consumes around 121.36 terawatt-hours (TWh) a year - and is 
              unlikely to fall unless the value of the currency slumps. The electricity consumption exceeds that of Argentina (121TWh), the 
              Netherlands (108.8TWh) and the United Arab Emirates (113.20TWh) - and it is gradually creeping up in Norway (122.20TWh). Imagine 
              the energy consumption when the Blockchain technology market exceeds USD 3 TRILLION by 2030 if sustainability of Blockchain is not 
              resolved!'
              label='Current Unsustainable Blockchain Technologies'
              path='/exec-summary'
            />
            <CardItem
              src='images/img-2.jpg'
              text='The problems that we have identified, but not limited to, are in the food, textile and health sectors and our technology would 
              make it easier to track, trace and report the origin of processes and ingredients of the products. Our blockchain will report 
              the complete end-to-end (earth to shelve) lifecycle of any product and its ingredients and processes, by using blockchain 
              technology’s immutability. The immutability of the blockchain means that once the relevant data is saved in the blockchain, it 
              cannot be altered and forms a permanent record. This record can then be made accessible to all relevant stakeholders, using the 
              transparency and security features of our blockchain technology. These features will include, but will not be limited to, 
              splitting the data into private and public viewable blocks, giving the owner of the process and data control over their trade 
              secrets, but still allowing full transparency of the data of public interest.'
              label='Where will our Blockchain be used'
              path='/exec-summary'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/img-3.jpg'
              text='Cartallum Blockchain identified current blockchain development and design problems above and we are developing a sustainable 
              solution to address these issues. This will include creating a new blockchain technology with a cost effective and efficient 
              consensus algorithm. We will build a new blockchain technology with enhanced security around a multi-dimensional ledger.  The 
              relevant industries mentioned above not only to promote sustainability of finite resources, but to promote integrity through 
              transparent and fair farming and supply chain practices.'
              label='What problems is our Blockchain solving'
              path='/exec-summary'
            />
            <CardItem
              src='images/img-4.jpg'
              text='The blockchain technology will be licensed to each segment.  Each segment will connect purchaser and seller anonymously, creating 
              a platform either directly or via an auction, whereby goods are traded on a global basis.  A cryptocurrency is utilised to 
              facilitate payment, the exchange of currency to be facilitated by Cartallum Blockchain B.V.'
              label='How is our Blockchain different'
              path='/exec-summary'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SummaryCards;