import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function HomeCards() {
  return (
    <div className='cards'>
      <h1>Check out these exciting challenges and development prospects!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/consumer-img-1.jpg'
              text='Cartallum is pioneering the development of a novel sustainable blockchain technology to 
              promote sustainability, transparency, traceability, and security in the food, textile, and health 
              sectors. Our technology anomalously connects the seller with the buyer. '
              label='Power To The Consumer'
              path='/exec-summary'
            />
            <CardItem
              src='images/sustainable-img-1.jpg'
              text='At Cartallum, we believe that our novel sustainable blockchain solution will soon become 
              one of the biggest segments in the industry. We envision a world where blockchain technology can 
              transform multiple sectors without consuming the same amount of electricity as the Netherlands 
              on an annual basis.'
              label='What We Do'
              path='/exec-summary'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default HomeCards;