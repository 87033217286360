import React from 'react';
import '../../App.css';
import SummaryCards from '../SummaryCards.js';
import Footer from '../Footer.js';

function ExecSummary() {
  return (
    <>
    <div className='exec-summary-container'>
      <h1 className='exec-summary'>EXECUTIVE SUMMARY</h1>
    </div>
      <SummaryCards />
      <Footer />
    </>
  );
}

export default ExecSummary;