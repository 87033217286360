import React from 'react';
import '../../App.css';
import Footer from '../Footer.js';

export default function Products() {
  return (
    <>
    <div className='products-container'>
      <h1 className='products'>WHAT PRODUCTS DO WE OFFER</h1>
      <h2 className='products-more'> ... keeping scrolling for more information ...</h2>
    </div>
    <div className='product-details-container'>
      <p className='product-detail'> Watch this space for our upcoming product launch</p>
    </div>
    <Footer />
    </>
  );
}