import React from 'react';
import './Cards.css';
import { CardiFrame } from './CardItem';

function WhitePaperCard() {
  return (
    <div className='cards'>
      <h1>Please find the latest version of our Whitepaper here</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items_1'>
            <CardiFrame
              src='../../documents/Cartallum Blockchain Whitepaper v1.0.3.pdf'
              title='Whitepaper HTML'
              fullscreen='true'
              text='Whitepaper HTML'
              label='WhitePaper-HTML'
              target='_blank'
              type='text/html'
              height='700px'
              width='1000px'
              position='relative'
              margin='-300px auto 0 auto'
              top='5%'
              path='../../documents/Cartallum Blockchain Whitepaper v1.0.2.htm'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default WhitePaperCard;