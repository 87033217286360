import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path}>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt=''
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
        </Link>
      </li>
    </>
  );
}

function CardiFrame(props) {
  return (
    <>
      <li className='cards__iframe'>
        <Link className='cards__iframe__link' to={props.path}>
          <figure className='cards__iframe__pic-wrap' data-category={props.label}>
            <iframe
              src={props.src}
              name={props.title}
              title={props.title}
              allowfullscreen={props.fullscreen}
              height={props.height}
              width={props.width}
              position={props.position}
              margin={props.margin}
              top={props.top}
            />
          </figure>
          {/* <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
            <a href={props.path} type={props.type} target={props.target}>{props.text}</a>
          </div> */}
        </Link>
      </li>
    </>
  );
}

export default CardItem;
export {CardiFrame};