import React from 'react';
import '../App.css';
import ButtonLink, { Button } from './Button';
import './CartSection.css';

function CartSection() {
  return (
    <div className='cart-container'>
      <video src='/videos/video-1.mp4' autoPlay loop muted />
      <h1>THE NEW BLOCKCHAIN ADVENTURE IS HERE</h1>
      <p>What are you waiting for?</p>
      <div className='cart-btns'>
        <ButtonLink
          linkURL='/whitepaper'
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          onClick={console.log('clicking ButtonLink')}
        >
          GET STARTED BY READING THE WHITEPAPER
        </ButtonLink>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey buddy, you are clicking me')}
        >
          WATCH TRAILER <i className='far fa-play-circle' />
        </Button>
      </div>
    </div>
  );
}

export default CartSection;