import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link, NavLink } from 'react-router-dom';
import cartallum_icon_1 from '../images/white_logo_color_background.jpg'

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join our Sustainable Blockchain Adventure and receive updates on new developments
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='name'
              type='name'
              placeholder='Your Name'
            />
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/whitepaper'>Whitepaper</Link>
            <Link to='/'>Testimonials</Link>
            <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Contact</Link>
            <Link to='/'>About us</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Watch this space</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to={{ pathname: "https://www.linkedin.com/company/cartallumblockchain/"}} 
                  target="_blank">
              LinkedIn
            </Link>
            <Link to={{ pathname: "https://twitter.com/cartallum"}} 
                  target="_blank">
              Twitter
            </Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <NavLink to='/' className='cartallum-logo'
                  activeStyle={{
                    fontWeight: "bold",
                    color: "orange"
                  }}
            >
              <img class='cartallum-logo' 
                 src={cartallum_icon_1}
                 alt=''
               />
            </NavLink>
          </div>
          <small class='website-rights'>Cartallum © 2021</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link linkedin'
              to={{ pathname: "https://www.linkedin.com/company/cartallumblockchain/"}}
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to={{ pathname: "https://twitter.com/cartallum"}}
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;