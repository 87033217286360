import React from 'react';
import '../../App.css';
import Footer from '../Footer.js';

export default function SignUp() {
  return (
    <>
    <div className='sign-up-container'>
      <h1 className='sign-up'>LIKE & SUBSCRIBE</h1>
    </div>
    <Footer />
    </>
  );

}