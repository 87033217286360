import React from 'react';
import '../../App.css';
import WhitePaperCard from '../WhitePaperCard.js';
import Footer from '../Footer.js';

function WhitePaper() {
  return (
    <>
    <div className='whitepaper-container'>
      <h1 className='whitepaper'>Whitepaper</h1>
    </div>
      <WhitePaperCard />
      <Footer />
    </>
  );
}

export default WhitePaper;